import styled, { css } from 'styled-components';

export const SliderWrap = styled.div`
    position: relative;

    h2 {
        margin-bottom: 2rem;
    }
`;

export const Heading = styled.div`
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        h2 {
            max-width: 17rem;
            margin: 0 auto 2rem;
        }
    }

    @media (min-width: 1318px) {
        position: absolute;
        text-align: left;
        top: 5rem;
        left: 56rem;
        width: 20rem;
    }
`;

export const Slide = styled.div`
    flex: 0 0 100%;
    min-width: 0;
    position: relative;
`;

export const ImageWrap = styled.div`
    overflow: hidden;
    border-radius: 50%;
    transform: scale(0);
    will-change: transform;
    transition: transform 0.6s cubic-bezier(0.45, 0, 0.55, 1);
    z-index: 20;
    position: relative;

    left: 0;

    img {
        max-width: 100%;
        height: auto;
    }

    ${Slide}.active & {
        transform: scale(1);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        left: 5%;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 36.75rem;
        height: 36.75rem;
        z-index: 10;
    }
    @media (min-width: 973px) and (max-width: 1314px) {
        width: 25rem;
        height: 25rem;
        left: 15%;
    }
    @media (min-width: 783px) and (max-width: 970px) {
        width: 25rem;
        height: 25rem;
        left: 5%;
    }
    @media (min-width: 496px) and (max-width: 783px) {
        width: 25rem;
        height: 25rem;
        margin: 0 auto;
        left: 0;
    }
`;

export const Quote = styled.div`
    border-radius: 50%;
    position: relative;
    color: ${({ theme }) => theme.colours.text.default};
    background-color: ${({ theme }) => theme.colours.surface.default};
    transform: scale(0);
    will-change: transform;
    transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1) 0.2s;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 4rem 3rem 3rem 3rem;
    z-index: 10;

    margin: -3rem auto 0 auto;
    width: 22rem;
    height: 22rem;
    max-width: 100%;

    h3 {
        margin-bottom: 1.75rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
    }

    ${Slide}.active & {
        transform: scale(1);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        position: absolute;
        top: 12.5rem;
        left: 30rem;
        width: 24rem;
        height: 24rem;
        z-index: 20;
        padding: 2rem 3rem 2rem 5.4rem;
        margin: 0;
    }

    @media (min-width: 973px) and (max-width: 1314px) {
        left: 45%;
        width: 25rem;
        height: 25rem;
        top: 0;
    }

    @media (min-width: 783px) and (max-width: 970px) {
        position: absolute;
        top: 0;
        left: 45%;
        width: 25rem;
        height: 25rem;
        z-index: 20;
        padding: 0 4rem 2rem 5rem;
        margin: 0;

        h2 {
            margin: 0 0 0.5rem;
            font-size: 2rem;
            line-height: 2.2rem;
        }

        p {
            font-size: 01rem;
            line-height: 1.2rem;
        }
    }
`;

export const Details = styled.div`
    text-align: center;
    margin: 2.2rem 0 1.55rem;
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: black;

    @media (min-width: 1318px) {
        position: absolute;
        margin: 0;
        top: 16.5rem;
        left: 56rem;
        width: 17rem;
        height: 24rem;
        font-size: 1.4rem;
        line-height: 2.25rem;
        text-align: left;
    }
`;

export const Dot = styled.div<{ variant?: string }>`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    transform: scale(0);
    will-change: transform;
    transition: transform 0.3s cubic-bezier(0.45, 0, 0.55, 1);
    right: 2rem;
    top: 18rem;
    z-index: 100;

    ${Slide}.active & {
        transform: scale(1);
    }

    ${({ variant }) =>
        variant === 'secondary' &&
        css`
            background-color: rgba(255, 255, 255, 1);
            transition: transform 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0.3s;
            right: 0;
            top: 20rem;
            width: 2.5rem;
            height: 2.5rem;
        `}

    @media (min-width: 1318px) {
        width: 2.5rem;
        height: 2.5rem;
        left: calc(50% - 4rem);
        right: auto;
        top: 4rem;

        ${({ variant }) =>
            variant === 'secondary' &&
            css`
                width: 4.875rem;
                height: 4.875rem;
                left: calc(50% + 1rem);
                top: 6rem;
            `}
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;

    @media (min-width: 1318px) {
        flex-direction: row;
        gap: 3rem;
        position: absolute;
        top: 28rem;
        left: 56rem;
    }
`;

export const SlideControls = styled.div`
    display: flex;
    gap: 0.8rem;
`;
