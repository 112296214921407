import { useCallback, useEffect } from 'react';

import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

import { ITestimonialSliderBlock } from '@/interfaces/pages';
import { Button, Container, Section, SliderButton } from '@/styles/global';

import { Buttons, Details, Dot, Heading, ImageWrap, Quote, Slide, SlideControls, SliderWrap } from './styles';

interface ITestimonialSlider {
    content: ITestimonialSliderBlock;
}

const SLIDER_SPEED = 700;

const TestimonialSlider = ({ content }: ITestimonialSlider) => {
    const { heading, background, testimonials, cta } = content;

    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: 'start',
        loop: true,
        skipSnaps: false,
        inViewThreshold: 0.1,
        draggable: false,
        speed: 100,
    });

    const nextSlide = useCallback(() => {
        if (emblaApi) {
            const numSlides = emblaApi.slideNodes().length;
            const slideNodes = emblaApi.slideNodes();
            const isLastSlide = numSlides === emblaApi.selectedScrollSnap() + 1;
            const slideNodeNext = isLastSlide ? slideNodes[0] : slideNodes[emblaApi.selectedScrollSnap() + 1];
            const slideNodeCurrent = slideNodes[emblaApi.selectedScrollSnap()];
            slideNodeCurrent.classList.remove('active');

            const timer = setTimeout(() => {
                emblaApi.scrollNext();
                slideNodeNext.classList.add('active');
            }, SLIDER_SPEED);

            return () => clearTimeout(timer);
        }
    }, [emblaApi]);

    const prevSlide = useCallback(() => {
        if (emblaApi) {
            const numSlides = emblaApi.slideNodes().length;
            const slideNodes = emblaApi.slideNodes();
            const isFirstSlide = emblaApi.selectedScrollSnap() === 0;
            const slideNodePrevious = isFirstSlide
                ? slideNodes[numSlides - 1]
                : slideNodes[emblaApi.selectedScrollSnap() - 1];

            const slideNodeCurrent = slideNodes[emblaApi.selectedScrollSnap()];
            slideNodeCurrent.classList.remove('active');

            const timer = setTimeout(() => {
                emblaApi.scrollPrev();
                slideNodePrevious.classList.add('active');
            }, SLIDER_SPEED);

            return () => clearTimeout(timer);
        }
    }, [emblaApi]);

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            document.body.className = background;
        }
    }, [inView, background]);

    return (
        <Section ref={ref}>
            <Container>
                <SliderWrap>
                    <Heading>
                        <h2>{heading}</h2>
                    </Heading>
                    <div className="embla">
                        <div className="embla__viewport" ref={emblaRef}>
                            <div className="embla__container">
                                {testimonials.map((testimonial) => {
                                    const {
                                        id: slideID,
                                        name,
                                        image,
                                        quote,
                                        cta: personCTA,
                                        position,
                                        location,
                                    } = testimonial;

                                    return (
                                        <Slide
                                            key={`slide-${slideID}`}
                                            className={inView && slideID === 1 ? 'active' : ''}
                                        >
                                            <ImageWrap>
                                                <Image
                                                    src={image.path}
                                                    alt={image.alt}
                                                    width={588}
                                                    height={588}
                                                    unoptimized
                                                />
                                            </ImageWrap>
                                            <Quote>
                                                <div>
                                                    <h3>{quote}</h3>
                                                    <Button arrow="true" href={personCTA.url}>
                                                        {personCTA.text}
                                                    </Button>
                                                </div>
                                            </Quote>
                                            <Details>
                                                <strong>{name}</strong> <br />
                                                {position}
                                                <br />- {location}
                                            </Details>
                                            <Dot />
                                            <Dot variant="secondary" />
                                        </Slide>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <Buttons>
                        <SlideControls>
                            <SliderButton variant="prev" onClick={prevSlide} aria-label="Previous" />
                            <SliderButton variant="next" onClick={nextSlide} aria-label="Next" />
                        </SlideControls>
                        {cta && (
                            <Button arrow="true" color="blue" href={cta.url}>
                                {cta.text}
                            </Button>
                        )}
                    </Buttons>
                </SliderWrap>
            </Container>
        </Section>
    );
};

export default TestimonialSlider;
